<template>
  <DynamicView>
    <TableView
      :heading="$t('pages.scoreAdmin')"
      :description="$t('score.fullRights')"
      :hasPagination="false"
      v-bind="{ buttons, tabs }"
    />
    <CreateQuestionnaireDialog
      v-model="createQuestionnaireDialog"
      @finish="questionnaireCreated"
    />
  </DynamicView>
</template>

<script>
import { DynamicView, TableView } from '@/components/layout'
import { CreateQuestionnaireDialog } from '@/components/dialog'

export default {
  components: {
    DynamicView,
    TableView,
    CreateQuestionnaireDialog,
  },
  data() {
    return {
      createQuestionnaireDialog: false,
      newQuestionnaire: {
        value: null,
      },
      activeQuestionnaireId: {
        value: null,
      },
      numQuestionnaires: {
        value: 0,
      },
    }
  },
  provide() {
    return {
      // used to trigger refetch of list after new quest created
      newQuestionnaire: this.newQuestionnaire,
      // used to set id of duplicate (needs to equal active quest id, [don't ask ...])
      activeQuestionnaireId: this.activeQuestionnaireId,
      // used to determine whether create request is POST or PUT (don't ask ...)
      numQuestionnaires: this.numQuestionnaires,
    }
  },
  computed: {
    buttons() {
      return [
        {
          label: this.$t('score.createQuestionnaire'),
          onClick: this.openCreateQuestionnaireDialog,
        },
      ]
    },
    tabs() {
      return [
        {
          routeName: 'questionnairesActive',
          label: this.$t('score.questionnairesActive-tab'),
        },
        {
          routeName: 'questionnairesArchived',
          label: this.$t('score.questionnairesArchived-tab'),
        },
      ]
    },
  },
  methods: {
    openCreateQuestionnaireDialog() {
      this.createQuestionnaireDialog = true
    },
    questionnaireCreated(questionnaire) {
      this.newQuestionnaire.value = questionnaire
    },
  },
}
</script>
