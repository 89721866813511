<template>
  <div>
    <ul class="flex flex-wrap p-4">
      <template v-if="searchedFilteredQuestionnaires.length > 0">
        <li
          class="p-6"
          v-for="questionnaire in searchedFilteredQuestionnaires"
          :key="questionnaire.id"
        >
          <Tile
            v-bind="{ questionnaire }"
            @copy="openDuplicateDialog"
            @delete="openDeleteDialog"
          />
        </li>
      </template>
      <li v-else class="flex-grow text-center font-medium py-16">
        {{
          pending
            ? `${$t('general.loading')} ...`
            : error
            ? `${$t('table.loadError')}.`
            : `${$t('table.empty')}.`
        }}
      </li>
    </ul>

    <DeleteQuestionnaireDialog
      v-model="deleteDialog"
      :questionnaire="selectedQuestionnaire"
      @delete="deleteQuestionnaire"
    />
    <DuplicateQuestionnaireDialog
      v-model="duplicateDialog"
      :questionnaire="selectedQuestionnaire"
      @duplicate="questionnaireDuplicated"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { EQuestionnaireState } from '@/enums'
import { useListFetch } from '@/composition'
import { Tile } from '@/components/questionnaire'
import {
  DeleteQuestionnaireDialog,
  DuplicateQuestionnaireDialog,
} from '@/components/dialog'
import { general } from '@/helpers'

export default {
  components: {
    Tile,
    DeleteQuestionnaireDialog,
    DuplicateQuestionnaireDialog,
  },
  props: {
    search: {
      type: String,
      required: true,
    },
  },
  inject: ['newQuestionnaire', 'activeQuestionnaireId', 'numQuestionnaires'],
  setup() {
    const store = useStore()
    const errorMessage = () =>
      store.dispatch('toastMessage/showMessage', {
        type: 'error',
        translationKey: 'questionnaire-list-fetch-error',
      })
    return useListFetch('questionnaires', errorMessage)
  },
  data() {
    return {
      selectedQuestionnaire: null,
      deleteDialog: false,
      duplicateDialog: false,
    }
  },
  computed: {
    activeQuestionnaires() {
      return this.list?.filter(q => q.state !== EQuestionnaireState.Archived)
    },
    archivedQuestionnaires() {
      return this.list?.filter(q => q.state === EQuestionnaireState.Archived)
    },
    filteredQuestionnaires() {
      switch (this.$route.name) {
        case 'questionnairesActive':
          return this.activeQuestionnaires
        case 'questionnairesArchived':
          return this.archivedQuestionnaires
        default:
          return this.archivedQuestionnaires
      }
    },
    searchTerm() {
      return this.search.trim().toLowerCase()
    },
    searchedFilteredQuestionnaires() {
      if (!this.filteredQuestionnaires) {
        return []
      }
      const searchKeys = ['title']
      return general.searchOverKeys(
        this.filteredQuestionnaires,
        searchKeys,
        this.searchTerm
      )
    },
  },
  methods: {
    openDeleteDialog(questionnnaire) {
      this.selectedQuestionnaire = questionnnaire
      this.deleteDialog = true
    },
    openDuplicateDialog(questionnnaire) {
      this.selectedQuestionnaire = questionnnaire
      this.duplicateDialog = true
    },
    deleteQuestionnaire(questionnaire) {
      this.list = this.list.filter(q => q.id !== questionnaire.id)
    },
    questionnaireDuplicated(questionnaire) {
      this.newQuestionnaire.value = questionnaire
    },
    async fetchList() {
      this.list = await this.request()
    },
  },
  watch: {
    'newQuestionnaire.value'() {
      this.fetchList()
    },
    'list'(newList) {
      // needed for create / duplicate dialog
      this.numQuestionnaires.value = newList.length
      this.activeQuestionnaireId.value = newList.find(
        q => q.state === EQuestionnaireState.Active
      )?.id
    },
  },
  beforeRouteEnter(_, from, next) {
    next(vm => {
      if (
        ['questionnairesActive', 'questionnairesArchived'].includes(from.name)
      ) {
        if (vm.pending) {
          vm.cancel()
        }
        vm.fetchList()
      }
    })
  },
}
</script>
